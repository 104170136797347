import React, { useState } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import Notiflix from "notiflix";
import { sendMessage } from "../services/sendMessage";
import { validateEmail, validateString } from "../utils/utils";
import { SocialMediaLinks } from "../components/SocialMediaLinks";
import { trackMixpanel } from "../utils/mixpanel";
import { ANALYTIC_EVENTS } from "../utils/constant";

const Contact = () => {
  useDocTitle({
    title: "Wishy | Contact Us for Support and Feedback",
    description:
      "Got questions or feedback? Send us a message and our dedicated team at Wishy will get back to you promptly. We value your insights.",
  });
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState(null);

  const clearErrors = () => {
    setErrors(null);
  };

  const clearInput = () => {
    setFullName("");
    setEmail("");
    setMessage("");
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const emailError = validateEmail(email);
    const fullNameError = validateString({
      value: fullName,
      fieldName: "Full name",
    });
    const messageError = validateString({
      value: message,
      fieldName: "Message",
    });

    if (emailError || fullNameError || messageError) {
      setErrors({
        ...(emailError && { email: emailError }),
        ...(messageError && { message: messageError }),
        ...(fullNameError && { full_name: fullNameError }),
      });
      return;
    }
    trackMixpanel(ANALYTIC_EVENTS.contactSent, {
      channel: "Website",
    });

    document.getElementById("submitBtn").disabled = true;
    document.getElementById("submitBtn").innerHTML = "Loading...";

    const result = await sendMessage({ fullName, email, message });

    if (result) {
      document.getElementById("submitBtn").disabled = false;
      document.getElementById("submitBtn").innerHTML = "send message";
      clearInput();
      Notiflix.Report.success(
        "Message Sent Successfully!",
        "Thank you for reaching out. We'll get back to you shortly!",
        "Return to website"
      );
    } else {
      document.getElementById("submitBtn").disabled = false;
      document.getElementById("submitBtn").innerHTML = "send message";
      Notiflix.Report.failure(
        "An error occurred",
        "We can't save your request now. Please try again later.",
        "Okay"
      );
    }
  };
  return (
    <>
      <div className="full-height">
        <div>
          <NavBar />
        </div>
        <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 bg-gray-200">
          <div
            className="container mx-auto my-8 px-4 lg:px-20"
            data-aos="zoom-in"
          >
            <form onSubmit={sendEmail} id="contactUs">
              <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                <div className="flex justify-center">
                  <h1 className="font-bold text-center lg:text-center text-black uppercase text-4xl">
                    Send us a message
                  </h1>
                </div>
                <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                  <div>
                    <input
                      name="full_name"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="Full Name*"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      onKeyUp={clearErrors}
                    />
                    {errors && (
                      <p className="text-red-500 text-sm">{errors.full_name}</p>
                    )}
                  </div>

                  <div>
                    <input
                      name="email"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="email"
                      placeholder="Email*"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyUp={clearErrors}
                    />
                    {errors && (
                      <p className="text-red-500 text-sm">{errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="my-4">
                  <textarea
                    name="message"
                    placeholder="Message*"
                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyUp={clearErrors}
                  ></textarea>
                  {errors && (
                    <p className="text-red-500 text-sm">{errors.message}</p>
                  )}
                </div>
                <div className="my-2 w-1/2 lg:w-2/4 mx-auto">
                  <button
                    type="submit"
                    id="submitBtn"
                    aria-label="submit-message"
                    className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-black text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline"
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
            <div className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-black rounded-2xl">
              <div className="flex flex-col text-white">
                <div className="flex my-4 w-2/3 lg:w-3/4">
                  <div className="flex flex-col">
                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                  </div>
                  <div className="flex flex-col">
                    <h2 className="text-2xl">Wishy LLC</h2>
                    <p className="text-gray-400">New Mexico, USA</p>
                  </div>
                </div>

                <div className="flex my-4 w-2/3 lg:w-1/2">
                  <div className="flex flex-col">
                    <i className="fas fa-phone-alt pt-2 pr-2" />
                  </div>

                  <div className="flex flex-col">
                    <h2 className="text-2xl">WhatsApp</h2>
                    <p className="text-gray-400">+972543142827</p>

                    <div className="mt-5">
                      <h2 className="text-2xl">Send an Email</h2>
                      <p className="text-gray-400">
                        <a
                          className="custom-a"
                          href="mailto:info@wishy.store"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          info@wishy.store
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex my-4 w-2/3 lg:w-1/2">
                  <SocialMediaLinks justifyContent="start" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
