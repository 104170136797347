import React from "react";
import requestImage from "../../images/request-image.webp";
import giftsImage from "../../images/gifts-image.webp";
import recommendationsImage from "../../images/recommendations-image.webp";
import matchImage from "../../images/match-image.webp";
import Feature from "./Feature";

const Features = () => {
  return (
    <>
      <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id="features">
        <Feature
          featureKey="match"
          title="1. Perfect Matches Await"
          subtitle="Welcome to Wishy, where shopping becomes a social adventure. Swipe right on items you love, and let the matches begin."
          description="Connect with like-minded users who share your taste – your shopping soulmates are just a swipe away! 🎉"
          buttonText="Start swiping"
          alt="Match image"
          image={matchImage}
        />
        <Feature
          featureKey="recommendations"
          title="2. Curated Recommendations"
          subtitle="But that's not all. Wishy's matches offer more than just connections. They open doors to personalized recommendations from fellow users and influencers."
          description="Dive into a world of products tailored to your preferences and passions. Get inspired and never miss out on the latest trends."
          buttonText="Start matching"
          alt="Recommendations image"
          image={recommendationsImage}
          isRtl={true}
        />
        <Feature
          featureKey="gifts"
          alt="Gifts image"
          title="3. Seamless Gifting, Effortless Joy"
          subtitle="Wishy isn't just about personal finds – it's about thoughtful gestures too. Gift matched items to loved ones or yourself effortlessly."
          description="Experience stress-free gifting and share the joy with Wishy's intuitive platform. Celebrate special occasions and create meaningful moments with ease."
          buttonText="Start exploring"
          image={giftsImage}
        />
        <Feature
          featureKey="request"
          alt="Request image"
          title="4. Express with a Swipe Up"
          subtitle="And the best part? Express your desires with a swipe up. Subtly hint at what you love and let your friends and family in on your wishes."
          description="No need for words – Wishy makes it easy to share your dreams. 🎁 Let the world know what sparks joy in your heart."
          buttonText="Start requesting"
          image={requestImage}
          isRtl={true}
        />
      </div>
    </>
  );
};

export default Features;
