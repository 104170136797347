import React from "react";
import { HashLink } from "react-router-hash-link";
import { SocialMediaLinks } from "./SocialMediaLinks";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
          <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
            <div className="col-span-12 lg:col-span-4">
              <div className="box-border border-b-4 border-black p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                <h3 className="font-bold text-4xl mb-4">Wishy LLC</h3>
                <div className="text-md font-medium text-gray-600">
                  <p>New Mexico,</p>
                  <p>USA.</p>
                </div>
              </div>
            </div>

            <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
              <h4 className="text-black text-xl font-bold mb-4">LINKS</h4>
              <ul className="text-md">
                <li className="mb-2">
                  <HashLink
                    to="/"
                    smooth="true"
                    className="text-gray-800 hover:text-black hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Home
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/#features"
                    smooth="true"
                    className="text-gray-800 hover:text-black hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Features
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/contact/"
                    smooth="true"
                    className="text-gray-800 hover:text-black hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Contact
                  </HashLink>
                </li>
                {/* <li className="mb-2">
                  <HashLink
                    to="/catalog/"
                    smooth="true"
                    className="text-gray-800 hover:text-black hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Product Catalog
                  </HashLink>
                </li> */}
                <li className="mb-2">
                  <HashLink
                    to="/privacy-terms/"
                    smooth="true"
                    className="text-gray-800 hover:text-black hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Privacy Terms
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/join-wait-list/"
                    smooth="true"
                    className="hidden"
                  >
                    Wait List
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 3rd block */}
            <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
              <h4 className="text-black text-xl font-bold mb-4">FEATURES </h4>
              <ul className="text-md">
                <li className="mb-2">
                  <HashLink
                    to="/#match"
                    smooth="true"
                    className="text-gray-800 hover:text-black hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Match
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    smooth="true"
                    to="/#recommendations"
                    className="text-gray-800 hover:text-black hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Recommendations
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    smooth="true"
                    to="/#gifts"
                    className="text-gray-800 hover:text-black hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Gifts
                  </HashLink>
                </li>
                <li className="mb-2">
                  <HashLink
                    to="/#request"
                    smooth="true"
                    className="text-gray-800 hover:text-black hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Request
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 4th block */}
            <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-black">
              <div className="text-xl mb-6">Social Media Links.</div>

              <div className="text-md font-medium mb-6">
                Follow us on social media.
              </div>
              <div className="mx-auto text-center mt-2">
                <SocialMediaLinks justifyContent="center" />
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
              <div className="text-sm text-gray-200 font-semibold py-1">
                Copyright &copy; {new Date().getFullYear()}{" "}
                <HashLink to="#" className=" hover:text-gray-900">
                  Wishy
                </HashLink>
                . All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
