import axios from "axios";

const sendMessage = async ({
  email,
  fullName,
  message = null,
  shopURL = null,
}) => {
  const url = process.env.REACT_APP_API_BASE_URL + "/messages";
  const body = {
    email,
    full_name: fullName,
    message,
    shop_url: shopURL,
  };

  try {
    const response = await axios.post(url, body);
    if (response.status !== 200) throw response;
    return true;
  } catch (error) {
    console.error(`Error: ${error}`);
    return false;
  }
};

export { sendMessage };
