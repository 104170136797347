import React from "react";
import NavBar from "../components/Navbar/NavBar";
import heroImg from "../images/app-image.webp";
import { trackMixpanel } from "../utils/mixpanel";
import { ANALYTIC_EVENTS } from "../utils/constant";

const Hero = () => {
  const handleDownload = (e) => {
    e.preventDefault();

    trackMixpanel(ANALYTIC_EVENTS.joinWishyClicked, {
      channel: "Website",
      context: "Hero",
    });

    window.location.href = "https://app.wishy.store/download";
  };

  return (
    <>
      <div className="hero" id="hero">
        <div>
          <NavBar />
        </div>

        <div
          className="m-auto overflow-hidden mx-4 lg:mx-12 mt-12 p-2 md:p-12 h-full lg:flex lg:items-center"
          data-aos="zoom-in"
        >
          <div className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
            <div
              className="lg:w-1/2 lg:pl-4 flex flex-col justify-center"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <h1 className="mb-5 md:text-5xl text-3xl font-bold text-black">
                Discover Every Wishy Fulfilled
              </h1>
              <h2 className="text-xl font-semibold tracking-tight mb-5 text-gray-500">
                Align your shopping with dreams. Find curated picks, gift
                effortlessly, and realize desires with Wishy.
              </h2>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <button
                  onClick={handleDownload}
                  className="text-white bg-black hover:bg-gray-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
                >
                  Start discovering Wishy
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div
              className="flex lg:justify-end w-full lg:w-1/2"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              <img
                height="100%"
                width="100%"
                fetchpriority="high"
                alt="app screenshot"
                className="rounded-t float-right duration-1000 mx-auto"
                src={heroImg}
                style={{ maxWidth: "500px", maxHeight: "500px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
