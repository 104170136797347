import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDocTitle } from "../components/CustomHook";
import NavBar from "../components/Navbar/NavBar";

const PrivacyTerms = (props) => {
  const [content, setContent] = useState("");

  useDocTitle({
    title: "Wishy | Privacy Policy",
    description:
      "Discover how Wishy manages and protects your personal data on our shopping platform. Our Privacy Policy details your rights and our privacy commitments.",
  });
  const containerStyle = {
    textAlign: "justify",
    margin: "0 auto",
    marginTop: "100px",
    maxWidth: "800px",
    padding: "20px",
  };

  useEffect(() => {
    axios
      .get("/privacy-policy.html")
      .then((response) => {
        setContent(response.data);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the privacy policy content:",
          error
        );
      });
  }, []);

  return (
    <>
      <NavBar />
      <div
        style={containerStyle}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </>
  );
};

export default PrivacyTerms;
