import { useEffect, useState } from "react";

const useDocTitle = ({ title, description }) => {
  const [doctitle, setDocTitle] = useState(title);
  const [docDescription, setDocDescription] = useState(description);

  useEffect(() => {
    document.title = doctitle;
    document.querySelector('meta[name="description"]').content = docDescription;
  }, [doctitle, docDescription]);

  return [doctitle, setDocTitle, docDescription, setDocDescription];
};

export { useDocTitle };
