import { ANALYTIC_EVENTS } from "./constant";
import mixpanel from "mixpanel-browser";

const initMixpanel = () => {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
};

const trackMixpanel = (eventName, variables = {}) => {
  try {
    const eventData = {
      ...variables,
      "User Agent": navigator.userAgent,
    };

    return mixpanel.track(eventName, eventData);
  } catch (error) {
    console.error(error);
  }
};

const pageViewedMixpanel = (path) =>
  trackMixpanel(ANALYTIC_EVENTS.pageViewed, { path });

export { initMixpanel, trackMixpanel, pageViewedMixpanel };
