import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import WaitList from "./pages/WaitList";
import ScrollToTop from "./components/ScrollToTop";
import NotFoundPage from "./pages/NotFoundPage";
import PrivacyTerms from "./pages/Privacy";

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-out-cubic",
    });

    window.addEventListener("load", AOS.refresh);
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact/" element={<Contact />} />
            <Route path="/join-wait-list/" element={<WaitList />} />
            <Route path="/privacy-terms/" element={<PrivacyTerms />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
