import React from "react";
import { HashLink } from "react-router-hash-link";
import { ANALYTIC_EVENTS } from "../../utils/constant";
import { trackMixpanel } from "../../utils/mixpanel";

const NavLinks = () => {
  const handleDownload = (e) => {
    e.preventDefault();

    trackMixpanel(ANALYTIC_EVENTS.joinWishyClicked, {
      channel: "Website",
      context: "Navbar",
    });

    window.location.href = "https://app.wishy.store/download";
  };

  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-black"
        smooth="true"
        to="/"
      >
        Home
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-black"
        smooth="true"
        to="/#features"
      >
        Features
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-black"
        smooth="true"
        to="/contact/"
      >
        Contact Us
      </HashLink>
      <button
        className="text-white bg-black hover:bg-gray-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
        smooth="true"
        onClick={handleDownload}
      >
        Download app
      </button>
    </>
  );
};

export default NavLinks;
