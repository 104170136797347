const validateEmail = (email) => {
  switch (email) {
    case "":
      return "Email is required";
    default:
      return null;
  }
};

const validateShopURL = (URL) => {
  switch (URL) {
    case "":
      return "Shop URL is required";
    default:
      return null;
  }
};

const validateString = ({ value, fieldName }) => {
  switch (value) {
    case "":
      return `${fieldName} is required`;
    default:
      return null;
  }
};

export { validateEmail, validateShopURL, validateString };
