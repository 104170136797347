import React, { useState } from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import Notiflix from "notiflix";
import { sendMessage } from "../services/sendMessage";
import { validateEmail, validateString } from "../utils/utils";
import { SocialMediaLinks } from "../components/SocialMediaLinks";

const WaitList = (props) => {
  useDocTitle({
    title: "Wishy | Secure Your Spot for Our Upcoming Launch",
    description:
      "Be among the first to experience Wishy's innovative shopping platform. Join our waitlist and gain early access when we go live.",
  });

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);
  const clearErrors = () => {
    setErrors([]);
  };

  const clearInput = () => {
    setFullName("");
    setEmail("");
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    const emailError = validateEmail(email);
    const fullNameError = validateString({
      value: fullName,
      fieldName: "Full name",
    });

    if (emailError || fullNameError) {
      setErrors({
        ...(emailError && { email: emailError }),
        ...(fullNameError && { full_name: fullNameError }),
      });
      return;
    }
    document.getElementById("submitBtn").disabled = true;
    document.getElementById("submitBtn").innerHTML = "Loading...";
    const result = await sendMessage({ fullName, email });

    if (result) {
      document.getElementById("submitBtn").disabled = false;
      document.getElementById("submitBtn").innerHTML = "send message";
      clearInput();
      Notiflix.Report.success(
        "Welcome Aboard!",
        "Thank you for joining our waitlist. We're excited to have you with us and will notify you soon!",
        "Return to website"
      );
    } else {
      document.getElementById("submitBtn").disabled = false;
      document.getElementById("submitBtn").innerHTML = "send message";
      Notiflix.Report.failure(
        "An error occurred",
        "We can't save your request now. Please try again later.",
        "Okay"
      );
    }
  };

  return (
    <>
      <div className="full-height">
        <div>
          <NavBar />
        </div>
        <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
          <div
            className="container mx-auto my-8 px-4 lg:px-20"
            data-aos="zoom-in"
          >
            <form onSubmit={sendEmail} id="joinWaitList">
              <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                <div className="flex justify-center">
                  <h1 className="font-bold text-center lg:text-center text-black uppercase text-4xl">
                    Join the wait list
                  </h1>
                </div>
                <div className="flex justify-center">
                  <h2 className="text-center lg:text-center text-black text-xl">
                    iOS version coming soon! Stay updated. Android available
                    now.
                  </h2>
                </div>
                <div className="grid grid-cols-1 gap-5 mt-5">
                  <div>
                    <input
                      name="full_name"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="Full Name*"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      onKeyUp={clearErrors}
                    />
                    {errors && (
                      <p className="text-red-500 text-sm">{errors.full_name}</p>
                    )}
                  </div>

                  <div>
                    <input
                      name="email"
                      className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="email"
                      placeholder="Email*"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyUp={clearErrors}
                    />
                    {errors && (
                      <p className="text-red-500 text-sm">{errors.email}</p>
                    )}
                  </div>
                </div>

                <div className="my-2 w-1/2 lg:w-2/4 mx-auto">
                  <button
                    aria-label="submit-message"
                    type="submit"
                    id="submitBtn"
                    className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-black text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline"
                  >
                    Notify me
                  </button>
                </div>
              </div>
            </form>
            <div className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-black rounded-2xl">
              <div className="flex flex-col text-white">
                <div className="flex my-4 w-2/3 lg:w-3/4">
                  <div className="flex flex-col">
                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                  </div>
                  <div className="flex flex-col">
                    <h2 className="text-2xl">Wishy LLC</h2>
                    <p className="text-gray-400">New Mexico, USA</p>
                  </div>
                </div>

                <div className="flex my-4 w-2/3 lg:w-1/2">
                  <div className="flex flex-col">
                    <i className="fas fa-phone-alt pt-2 pr-2" />
                  </div>

                  <div className="flex flex-col">
                    <h2 className="text-2xl">Phone</h2>
                    <p className="text-gray-400">+19402274086</p>

                    <div className="mt-5">
                      <h2 className="text-2xl">Send an Email</h2>
                      <p className="text-gray-400">
                        <a
                          href="mailto:info@wishy.store"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          info@wishy.store
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex my-4 w-2/3 lg:w-1/2">
                  <SocialMediaLinks justifyContent="start" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default WaitList;
