import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./App";
import { initMixpanel } from "./utils/mixpanel";
import TagManager from "react-gtm-module";

TagManager.initialize({
  gtmId: "GTM-NPHV3B78",
});
initMixpanel();
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
