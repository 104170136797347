import React from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { useDocTitle } from "../components/CustomHook";
import { Link } from "react-router-dom";

const NotFoundPage = (props) => {
  useDocTitle({
    title: "Wishy | 404 not found page",
    description: "",
  });

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
            <div className="flex justify-center">
              <h1 className="font-bold text-center lg:text-center text-black uppercase text-4xl">
                Oops! Page Not Found.
              </h1>
            </div>
            <div className="flex justify-center">
              <h2 className="text-center lg:text-center text-black text-xl">
                We couldn't find what you're looking for. Let's get you back on
                track.
              </h2>
            </div>

            <div className="my-2 w-1/2 lg:w-2/4 mx-auto">
              <Link
                to="/"
                className="text-white bg-black hover:bg-gray-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
              >
                Return to home
                <svg
                  className="w-4 h-4 ml-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFoundPage;
